import { ActionType, createReducer } from 'typesafe-actions';
import { setUserInfoAuth, setUserMode, setDeviceOrientation } from './actions';
import { IAuthState, ModeGame } from './types';

export const authState: IAuthState = {
  playerName: null,
  uuid: null,
  currency: null,
  mode: ModeGame.HOLI,
  jackpotOn: false,
  lang: null,
  operatorId: null,
  isMobileLandscape: false,
};

const actions = {
  setUserInfoAuth,
  setUserMode,
  setDeviceOrientation,
};

export const authReducer = createReducer<IAuthState, ActionType<typeof actions>>(authState)
  .handleAction(
    setUserInfoAuth,
    (state, { payload: { playerName, uuid, currency, mode, jackpotOn, lang, operatorId } }) => ({
      ...state,
      playerName,
      uuid,
      currency,
      mode,
      jackpotOn,
      lang,
      operatorId,
    }),
  )
  .handleAction(setUserMode, (state, { payload: mode }) => ({
    ...state,
    mode,
  }))
  .handleAction(setDeviceOrientation, (state, { payload }) => ({
    ...state,
    isMobileLandscape: payload,
  }));
